.search-bar-container {
	display: flex;
	position: relative;
	justify-content: center;
  padding-top: 5%;
}

.search {

	color: #555;
  display: flex;
  padding: 2px;
  border-radius: 5px;
  margin: 0 0 30px;
  border: none;
  background-color: #e7e7e769;
  border-radius: 50px;
	/* position: relative;
	display: inline-block */
}

.search>input{
	border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  border-radius: inherit;
  text-align: left;
	color: rgba(0, 0, 0, 0.37);
	height: 3vw;
	width: 100%;
  min-width: 35vw;
	padding: 1.25em 1.5em;
	font-size: 1.25rem;
}

.search>input:focus{
  outline: none;
}

.search-icon {
  overflow: hidden;
  width: 3rem;
  padding: 0.60rem 0.25rem 0rem 0rem;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent;
  cursor: pointer;
  opacity: 0.3;
	/* transform: translateY(-50%); */
}

@media (min-width: 3840px) {
  .search-icon  {  
    padding: 2.60rem 0.25rem 0rem 0rem; 
    
  }
  
	
	}


.search > button {
	position: absolute;
	border: none;
	background: none;
	top: 21%;
	right: 4%;
	font-weight: bolder;
}
