.navItem {
    padding: 0.8rem 0;
    text-decoration: none;
    display: block;
    color: #808080;
    margin: 0.5rem 0;
    align-items: center;
   
  }

  .navWrapper{
    height: auto;
    background-color: #454FDE;
    border-radius: 8px;
    color: white;
  }
  
  .navItem:hover {
    background-color: #454FDE;
    color: white;
    border-radius: 5px;
  }
  
  .activeNavItem {
    padding: 0.8rem 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #dbeafe;
    background-color: #454FDE;
    border-radius: 5px;
    word-spacing: 2px;
  }

  .childItem{
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    display: flex;
    color: white;
    margin: 0.5rem 0;
    align-items: center;
    
  }

  .childActiveItem{
    padding: 0.8rem 1.25rem;
    text-decoration: none;
    display: block;
    align-items: center;
    color: #dbeafe;
    background-color: #7b82e8;
    border-radius: 5px;
    word-spacing: 2px;
  }
  
  .navIcon {
    color: #d1d5db;
    padding: 0 0.75rem;

  }
  
  .navLabel {
    font-size: 1rem;
  }
  
  .navItemHeaderButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.75rem 1rem 0.75rem 0.25rem;
  }
  
  .navItemHeaderChevron {
    color: #d1d5db!important;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top:22%;
    left:15%;
    transition: all 0.25s;
  }
  
  .chevronExpanded {
    color: white;
    transform: rotate(180deg);
  }
  
  .navChildrenBlock {
    background-color: #454FDE;
    border-radius: 5px;
  }
  

  