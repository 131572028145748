.headline_title {
	text-align: center;
	position: relative;
	padding: 4rem 0;
	margin-top: 1rem;
}

.bgText {
	color: #ecebeb;
	font-weight: 700;
	font-size: 6.5rem;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
}

.mainText {
	font-size: 3rem;
	position: absolute;
	left: 50%;
	top: 58%;
	line-height: 3rem;
	font-family: "Montserrat";
	
	transform: translate(-50%, -50%);
}

.subText {
	font-size: 0.8rem;
	color: #8b8b8b;
	position: absolute;
	top: 90%;
	left: 50%;
	margin-top: 1%;
	transform: translate(-50%);
	font-weight: 300;
}

@media (max-width: 50em) {
	.headline_title {
		padding: 2rem 0rem;
		margin: 0rem 0 2rem 0;
	}
	.bgText {
		font-size: 5.5em;
	}
	.mainText {
		font-size: 2rem;
		text-align: center;
		line-height: 2rem;
		width: 100%;
		position: absolute;
		font-weight: 600;
	}

	.subText{
		margin-top: 2%;
	}
}
