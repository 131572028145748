.payment-form {
	padding-bottom: 50px;
	font-family: "Montserrat", sans-serif;
}

.payment-form.dark {
	background-color: #f6f6f6;
}

.payment-form .content {
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
	background-color: white;
}

.payment-form .block-heading {
	padding-top: 50px;
	margin-bottom: 40px;
	text-align: center;
}

.payment-form .block-heading p {
	text-align: center;
	max-width: 420px;
	margin: auto;
	opacity: 0.7;
}

.payment-form.dark .block-heading p {
	opacity: 0.8;
}

.payment-form .block-heading h1,
.payment-form .block-heading h2,
.payment-form .block-heading h3 {
	margin-bottom: 1.2rem;
	color: #3b99e0;
}

.payment-form form {
	border-top: 2px solid #5ea4f3;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
	background-color: #ffffff;
	padding: 0;
	max-width: 600px;
	margin: auto;
}

.payment-form .title {
	font-size: 1em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 0.8em;
	font-weight: 600;
	padding-bottom: 8px;
}

.payment-form .products {
	background-color: #f7fbff;
	padding: 25px;
}

.payment-form .products .item {
	margin-bottom: 1em;
}

.payment-form .products .item-name {
	font-weight: 600;
	font-size: 0.9em;
}

.payment-form .products .item-description {
	font-size: 0.8em;
	opacity: 0.6;
}

.payment-form .products .item p {
	margin-bottom: 0.2em;
}

.payment-form .products .price {
	float: right;
	font-weight: 600;
	font-size: 0.9em;
}

.payment-form .products .total {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-top: 10px;
	padding-top: 19px;
	font-weight: 600;
	line-height: 1;
}

.payment-form .card-details {
	padding: 25px 25px 15px;
}

.payment-form .card-details label {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 15px;
	color: #79818a;
	text-transform: uppercase;
}

.payment-loader{
		border: 5px solid #f3f3f3;
		border-radius: 50%;
		border-top: 5px solid #555;
		width: 60px;
		height: 60px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
}

.payment-form .card-details button {
	margin-top: 0.6em;
	padding: 12px 0;
	font-weight: 600;
}

.payment-form .date-separator {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 5px;
}

@media (min-width: 576px) {
	.payment-form .title {
		font-size: 1.2em;
	}

	.payment-form .products {
		padding: 40px;
	}

	.payment-form .products .item-name {
		font-size: 1em;
	}

	.payment-form .products .price {
		font-size: 1em;
	}

	.payment-form .card-details {
		padding: 40px 40px 30px;
	}

	.payment-form .card-details button {
		margin-top: 2em;
	}
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
