.sales_container{
    display: flex;
    padding: 1rem;
    gap:1rem
}

.sales_forecast_container{
    display: flex;
  flex-wrap: wrap;
  flex-direction: row;
    padding: 1rem;
    gap:0.5rem;
   justify-content: center;
    background-color: white;
}

.sales_forcast_card{
    display: flex; 
  flex-basis: calc(50% - 40px);  
  justify-content: center;
  flex-direction: column;
    position: relative;
    padding: 1rem;  
   height: 10vw;
   gap: 1rem;
   width: auto;
   
    border: 1px solid #e3e3e3;
    border-radius: 5%;
}

.sales_forcast_card:nth-child(odd) {
   
    flex: 0 0 50px;
  }

.card_bottom{
   
   padding-bottom: -5%;
}

.recent_order_container{
    display: flex; 
    padding:1rem;
    gap: 1rem;
    flex-direction: column;
}

.recent_orders{
    display: flex;
    border-radius: 5px;
    background-color: white;
    
}

table {
   
    width: 100%;
    height: auto;
  }
    
  th {
    
    padding: 1rem 0rem;
  }
    
  td {
    padding: 1rem 1rem 0.5rem 1rem;
    border-top: 1px solid #e3e3e3;
    text-align: center;
  }

