.coming-soon-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming-soon-content{
    padding: 7.5rem 2rem;
    width: 50%;
    text-align: center;
}

.coming-soon-content>h1{
    font-size: 4rem;
    font-weight: 400;
}

.coming-soon-content>p{
    font-size: 2rem;
    font-weight: 200;
    padding: 1rem;
}

.email-input{
    position: relative;
    padding: 1rem;
}

.email-input>input{
    width: 100%;
    font-size: 1.5rem;
    font-weight: 300;
	border: none;
	padding: 1rem 1.5rem;
	border-radius: 0.3rem;
    box-shadow: 0px 0px 108px rgba(0, 0, 0, 0.14);
}

.email-input>input:focus{
    outline: none;
}


.email-input>button{
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    position: absolute;
    background-color:#EE3364 ;
    top:26%;
    right: 4%;
}


.side-rectangle-right{
    position: absolute;
width: 341.66px;
height: 341.66px;
top:52%;
right:-12%;
background: linear-gradient(34.35deg, rgba(238, 51, 100, 0.98) 17.26%, rgba(236, 33, 86, 0.26) 238.21%);
border-radius: 36px;
transform: rotate(105deg);

}

.side-rectangle-left{
    position: absolute;
width: 341.66px;
height: 341.66px;
top:52%;
left:-14%;
background: linear-gradient(34.35deg, rgba(238, 51, 100, 0.98) 17.26%, rgba(236, 33, 86, 0.26) 238.21%);
border-radius: 36px;
transform: rotate(165deg);

}



