.compatibility {
	padding: 0 3.5rem;
	padding-bottom: 2rem;
	background: #f3f1f173;
}

.compatibility > .name {
	font-weight: 600;
	font-size: 1.5rem;
	padding: 1.2rem 0;
}

.compatible_icons {
	padding-bottom: 2rem;
	display: flex;
	gap: 1rem;
}
.compatible_icons > img {
	width: 2vw;
}

.compatible-product-buttons {
	display: flex;
	gap: 1rem;
}

@media (max-width: 50em) {
	.compatibility {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		padding: 0rem;
		background: #f3f1f173;
	}
	.compatibility > .name {
		padding: 1rem;
		width: 100%;
	}

	.compatible_icons {
		padding-left: 1rem;
		padding-bottom: 0rem;
		display: flex;
		gap: 1rem;
	}

	.compatible_icons > img {
		width: 2.5rem;
	}

	.compatible-product-buttons {
		display: flex;
		padding-left: 1rem;
	}
}
