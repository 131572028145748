.cart-item-text {
	font-weight: 600;
	font-size: medium;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}

.license {
	font-size: small;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}

.license {
	font-size: small;
	line-height: 116.5%;

	letter-spacing: 0.02em;
	color: #000000;
}

.list-item-price {
	grid-column: 3;
	font-weight: 700;
	font-size: 24px;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}
.cart-item-secondary-text {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: small;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #585858;
}

.item-details {
	grid-column: 2;
}

.license > span {
	color: #5f88f3;
	margin-left: 5;
}

.cart-item {
	margin: 0 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	border-bottom: 5px solid #d9d9d975;
}

.cart-item-image-1 {
	width: 22%;
	height: 8rem;
	border-radius: 0 2rem 2rem 0;
	padding: 0.5rem;
}

.action-button > button {
	color: #fff;
	padding: 10px;
	background-color: transparent;
}

.action-button .increase {
	background-color: black;
}

.action-button .decrease {
	color: var(--primary-color);
}

@media (max-width: 50em) {
	.cart-item {
		margin: 0;
		justify-content: center;
		height: 12vh;
	}

	.cart-item-secondary-text,
	.cart-item-image-1 {
		display: none;
	}

	.list-item-price {
		width: 4rem;
		font-weight: 700;
		font-size: 15px;
		line-height: 100%;
	}

	.cart-item-text {
		width: 15rem;
		font-weight: 600;
		font-size: 15px;
		line-height: 116.5%;
	}

	.action-button > button {
		width: 2rem;
		padding: 0.2rem;
	}

	.license > span {
		color: #5f88f3;
		margin-left: 5;
	}
	.license {
		padding: 0.2rem 0rem;
	}
}
