/* Float four columns side by side */
h1,
h5 {
	font-weight: 300;
}

.serviceColumn {
	float: left;
	width: 25%;
	padding: 0 10px;
}

/* Style the counter cards */
.serviceCard {
	font-size: 0.7rem;
	font-style: normal;
	vertical-align: baseline;
	fill: "black";
	width: 16rem;
	border-radius: 5%;
	background-color: #fbfbfb;
	padding: 1.5rem;
	transition: all 0.3s ease;
	text-align: center;
}



.serviceCard:hover {
	cursor: pointer;
	border-radius: 5%;
	text-align: center;
	background-color: #5f88f3;
	fill: white;
	color: whitesmoke;
	transform: translateX(0px) translateY(8px) scale(1.15) translateZ(0);
}

.service-img > svg {
	width: 7rem;
}

@media(min-width:3840px){
	.serviceCard{
		width: 22rem;
		height: 20rem;
	}
}

/* Responsive columns */
@media screen and (max-width: 50em) {
	.serviceColumn {
		width: 100%;
		display: block;
		margin-bottom: 20px;
	}
	.serviceCard {
		text-align: center;
		width: 18rem;
		padding: 4rem;
	}
	
}
