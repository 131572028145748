:root {
	--ff-primary: "Poppins";

	--primary-color: #ee3364;

	--ff-body: var(--ff-primary);
	--ff-heading: var(--ff-primary);

	--fw-regular: 400;
	--fw-semi-bold: 500;
	--fw-less-bold: 600;
	--fw-bold: 700;

	--fs-300: 0.8125rem;
	--fs-400: 0.875rem;
	--fs-500: 0.9375rem;
	--fs-600: 1rem;
	--fs-700: 1.875rem;
	--fs-800: 2.5rem;
	--fs-900: 3.5rem;

	--fs-body: var(--fs-400);
	--fs-primary-heading: var(--fs-800);
	--fs-secondary-heading: var(--fs-700);
	--fs-nav: var(--fs-500);
	--fs-button: var(--fs-300);

	--size-100: 0.25rem;
	--size-200: 0.5rem;
	--size-300: 0.75rem;
	--size-400: 1rem;
	--size-500: 1.5rem;
	--size-600: 2rem;
	--size-700: 3rem;
	--size-800: 4rem;
	--size-900: 5rem;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
* {
	margin: 0;
	padding: 0;
	font-family: var(--ff-primary);
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
	max-width: 100%;
	/* display: block; */
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* general styling */

body {
	font-size: var(--fs-body);
	font-family: var(--ff-body);
	/* color: var(--clr-primary-400); */
}

/* button */

button:hover {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

input:focus {
	outline: 1px solid grey;
}

textarea:focus {
	outline: 1px solid grey;
}

/* Utility classes */

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap; /* added line */
	border: 0;
}

.fw-bold {
	font-weight: var(--fw-bold);
}
.fw-semi-bold {
	font-weight: var(--fw-semi-bold);
}
.fw-regular {
	font-weight: var(--fw-regular);
}

.fs-primary-heading {
	font-size: var(--fs-primary-heading);
	line-height: 1.1;
}
.fs-secondary-heading {
	font-size: var(--fs-secondary-heading);
	line-height: 1.1;
}

.fs-300 {
	font-size: var(--fs-300);
}
.fs-400 {
	font-size: var(--fs-400);
}
.fs-500 {
	font-size: var(--fs-500);
}
.fs-600 {
	font-size: var(--fs-600);
}

.padding-block-900 {
	padding-block: var(--size-900);
}
.padding-block-700 {
	padding-block: var(--size-700);
}

.display-sm-none {
	display: none;
}

.App {
	position: relative;
	min-height: 100vh;
}

@media (min-width: 50em) {
	.display-md-inline-flex {
		display: inline-flex;
	}
}
