.author-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
}

.author-content>h1{
    font-size:3.5rem;
    color: #EE3364;
    font-weight: 900;
}

.author-benifit-layout{
    display: grid;
    grid-template-columns: repeat(4, auto);
	gap: 2rem;
    padding: 4rem;
}

.author-benifits{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 3rem;
    flex-direction: column;
}



.author-benifits>div>p{
   font-size: 1rem;
   font-weight: 200;
   padding: 1rem;
}

.author-benifits>div>img{
    height: 5rem;
}


@media (max-width: 50em) {
    .author-benifit-layout{
        display: grid;
        grid-template-columns: repeat(1, auto);
        gap: 2rem;
        padding: 4rem;
    }

    .author-content>h1{
        font-size: 2.15rem;
        text-align: center;
    }
}



