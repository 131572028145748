.services {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	padding: 4rem;
}

.service_container{
	margin-top: 1%;
}



.service-layout {
	display: flex;
	align-items: center;
	padding: 0 1%;
	margin: 10px;
}

/* Clear floats after the columns */
.service-layout:after {
	content: "";
	display: table;
	clear: both;
}

.service-man {
	z-index: 1;
	position: absolute;
	
	bottom: 0;
	width: 15%;
	display: block;
	left: 5%;
	
}

.bgstrip1 {
	left: 0;
	bottom: 0;
	top: -58%;
}

.bgstrip2 {
	position: absolute;
	right: 0;
	bottom: -30%;
}

.services-heading {
	font-size: 2.5em;
	font-family: "Montserrat", sans-serif;
}

/* @media (max-width: 800px) {
	.flex-container {
		flex-direction: column;
	}
} */

@media screen and (max-width: 767px) {
	.services {
		display: grid;
		justify-content: center;
		position: relative;
		padding: 0rem;
	}
}
@media(min-width:3840px){
	.service_container{
		margin-top: 15%;
	}
	.service-man{
		width: 10vw;
		left:12%;
		bottom: -12%;
	}
	.service-layout {
		padding: 0 2%;
	}

	.bgstrip1 {
		margin-top: -5%;
	}

	.bgstrip2{
		margin-bottom: -8%;
	}
}
@media (max-width: 50em) {
	.service-man,
	.bgstrip1,
	.bgstrip2 {
		display: none;
	}
	.service-layout {
		margin: -5px;
	}
}
