.contactUs {
	display: flex;
	justify-content: space-around;
	padding: 6rem 4rem;
	gap: 1rem;
}

.card {
	border-radius: 17.0872px;
	padding: 1.5rem;
	display: flex;
	gap: 2rem;
	justify-content: left;
	align-items: center;
	width: 90%;
}

.card-icon-phone {
	width: 3rem;
}

.card-icon-location {
	width: 5.25rem;
}

.content {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	line-height: 1.5rem;
}

.title {
	color: #5f88f3;
	font-size: 1.25rem;
	font-weight: 700;
}

.details {
	font-size: 1rem;
	color: #808080;
	width: 70%;
}

.card:hover{
	box-shadow: 0px 4px 20px rgba(208, 208, 208, 0.25);
}

@media (max-width: 50em) {
	.contactUs {
		display: grid;
		justify-content: space-around;
		padding: 1rem;
		gap: 0rem;
	}

	.details {
		font-size: 0.9rem;
		font-weight: 500;
		color: #808080;
	}
}
