.order-list {
	display: flex;
	flex-direction: column;
	align-items: normal;
	justify-content: space-around;
	padding: 2rem 5rem 7rem 5rem;

	/* border-right: 2px solid black;
	border-left: 2px solid black;
	border-top: 2px solid black; */
}

.order-item-text {
	font-weight: 600;
	font-size: medium;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}

.license {
	font-size: small;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}

.item-price {
	grid-column: 3;
	font-weight: 700;
	font-size: 24px;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}
.order-item-secondary-text {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: small;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #585858;
}

.item-details {
	grid-column: 2;
	width: 70%;
}

.order-item {
	/* padding: 1rem; */
	/* justify-content: space-around; */

	/* display: flex; */
	/* flex-direction: row; */
	/* align-items: center; */

	/* gap: 3.25rem; */
	/* border-radius: 5px; */
	/* background-color: #fafafa; */
	/* border-bottom: 5px solid #d9d9d975; */

	margin: 0 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	border-bottom: 2px solid #d9d9d975;
}

.order-item-image {
	/* padding: 0.75rem;
	width: 20%;
	height: auto;
	border-radius: 25.2591px; */

	width: 25%;
	height: 200px;
	border-radius: 20px;
	padding: 0.5rem;
}

.action-buttons > button {
	color: #fff;
	padding: 10px;
	margin: 2px;
	border-radius: 5px;
}

.action-buttons .increase {
	background-color: black;
}

.action-buttons .decrease {
	background-color: var(--primary-color);
}

.order-rating {
	margin-top: 0.5rem;
}

@media (max-width: 50em) {
	.order-list {
		padding: 1rem 1rem 7rem 1rem;
	}
	.order-item-image {
		padding: 0.75rem;
		width: 100%;
		height: auto;
		border-radius: 25.2591px;
	}
	.order-item {
		padding: 1rem;
		justify-content: space-around;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.25rem;
		border-radius: 5px;
		background-color: #fafafa;
		border-bottom: 5px solid #d9d9d975;
	}
}
