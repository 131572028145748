.authorstrip-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 10rem 7rem 10rem;
}

.authorstrip-layout{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    width: 100%;
    border-radius: 2rem;
}

.authorstrip-content{
    
    padding: 3rem;

}

.authorstrip-content>h1{
    font-weight: 600;
    font-size: 3rem;
}

.authorstrip-content>p{
    font-weight: 300;
    font-size: 1.5rem;
    margin-top: 1%;
}

.authorstrip-button{
    padding: 4rem;
    text-align: right;
}

.authorstrip-button>button{
    font-size: 1rem;
    color: black;
    background-color: white;
    width: 12rem;
    padding: 1rem;
}

@media (max-width: 50em){
    .authorstrip-container{
        padding: 1rem 1rem 4rem 1rem;
        width: 100%;
    }

    .authorstrip-layout{
        flex-direction: column;
    }

    .authorstrip-button{
        padding: 2rem;
    }

    .authorstrip-content{
    
        padding: 2rem;
    
    }
}