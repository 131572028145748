.project_container {
	background-color: #f5f6fe;
}

.project_actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem 0 2rem;
}

.project_actions > h2 {
	text-align: center;
}

.project_actions > button {
	background-color: #454fe8;
	padding: 0.5rem 1rem;
	margin: 2rem;
}

.action_btn_icon {
	vertical-align: text-top;
	margin-right: 0.25rem;
}

button {
	padding: 0.5rem;
	margin: 0.25rem;
	border-radius: 5px;
	background-color: #d9d9d93b;
}

.project_table {
	display: flex;
	padding: 1rem 2rem;
}

.project_table > th {
	border-radius: 10px;
}

table {
	border-collapse: collapse;
	border-radius: 10%;
}

th {
	color: white;
}

table th:first-child {
	border-radius: 10px 0 0 10px;
}

table th:last-child {
	border-radius: 0 10px 10px 0;
}

table > tr:first-child {
	background-color: #454fdea1;
	border-radius: 10px;
}

.input_conatiner {
	padding: 1rem 6rem 1rem 6rem;
}

.input_conatiner > p {
	text-align: center;
	padding: 1rem;
}

.custom_modal_image_container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
}

.custom_modal_image {
	height: 50%;
	width: 40%;
	padding: 1rem 0;
}

.project_form {
	padding: 0 0 5rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.project_form > button {
	position: absolute;
	background-color: #454fde;
	padding: 1rem;
	width: 100%;
	bottom: 3%;
	left: -1%;
}

.form_input {
	padding: 0.7rem;
	border-radius: 0.3rem;
	border: 1px solid #d8d8d8;
	background-color: #f8f8f8;
}

.input_form_container {
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 0.25rem;
}

.input_form_container > span {
	font-size: 0.75rem;
	color: red;
	padding: 0;
}

.pagination-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10 !important;
}
