.empty-cart {
	display: flex;
	flex-direction: column;
	padding: 100px;
	font-size: 24px;
	justify-content: center;
	align-items: center;
}

@media (max-width: 50em) {
	.empty-cart {
		flex-direction: column;
		padding: 20px;
		font-size: 24px;
		justify-content: center;
		align-items: center;
	}
}
