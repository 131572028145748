.signUp_fields {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.signUp_form > .signup_heading,
.with_email,
.login_image {
	display: flex;
	justify-content: center;
}

.signup_heading {
	font-weight: 700;
	font-size: 1.5rem;
}

.signUp_form > .not_registered {
	position: static;
	color: gray;
	font-weight: 300;
	padding-top: 0.3rem;
}

.with_email {
	font-weight: 500;
	font-size: 1rem;
	padding-top: 1rem;
}

.signUp_form > .fields {
	padding: 1rem 0.5rem;
	gap: 0.2rem;
}

.names {
	display: flex;
}

.signup_using {
	padding-top: 0.7rem;
	display: flex;
	position: relative;
	gap: 1rem;
	justify-content: center;
}

.signup_using > img {
	width: 3vw;
}

.signUp_form > button {
	margin: 1rem 0;
	width: 100%;
	padding: 0.8rem;
	border-radius: 0.3rem;
	color: white;
	background-color: #0055d7;
}

.fields > button:hover {
	color: rgb(197, 197, 197);
}

.signup_using > img:hover {
	transform: scale(1.1);
	transition: all ease-in-out 0.25s;
	cursor: pointer;
}

.split_input_signup {
	display: flex;
	gap: 1rem;
}

.split_input_signup > div {
	width: 100%;
}

.split_input_signup > div > input {
	border: 1px solid#00000026;
	border: 1.13914px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.3rem;
	padding: 0.7rem;
}

@media (max-width: 50em) {
	.signup_using > img {
		padding-top: 0rem;
		width: 3rem;
	}
}
