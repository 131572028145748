.ProductHeading {
	padding: 3rem 3.5rem;
	display: flex;
	justify-content: space-between;
}

.product_name > .name {
	font-weight: 600;
	font-size: 1.5rem;
}

.desc_title {
	font-size: 1rem;
}

.product_desc > p {
	font-size: 0.8rem;
	color: #585858;
}

.product-tags > img {
	margin: 0.125rem;
	width: 1.5rem;
}

.product_desc {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
}

.product_desc > img {
	width: 1.8vw;
}

.product_desc > p {
	font-weight: 300;
}

.featured_product {
	padding: 0.7rem 0.8rem;
	font-weight: 500;
	font-size: 1rem;
	border-radius: 0.5rem;
	background-color:#479f58;;
	color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.product_price {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.product_price > p {
	font-size: 2rem;
	font-weight: 600;
}

button {
	border: none;
	color: white;
}

.product_buttons {
	display: flex;
	gap: 1rem;
}

.btn_black {
	padding: 0.3rem 0.8rem;
	background-color: black;
	color: white;
	border-radius: 0.5rem;
	font-size: 0.6rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 0.3rem;
}

.btn_addToCart {
	padding: 0.7rem 0.8rem;
	font-weight: 500;
	font-size: 1.125rem;
	border-radius: 0.5rem;
	background-color: #3d8d2f;
	color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.btn_preview {
	background: #5f88f3;
}

.btn_preview {
	border-radius: 0.4rem;
	font-size: 0.7rem;
	font-weight: 600;
}

.btn_black > img {
	width: 0.8vw;
}

@media (max-width: 50em) {
	.ProductHeading {
		padding: 1rem;
	}
	.product-tags {
		display: none;
	}

	.product_name > .name {
		max-width: 90%;
	}
	.product_price {
		display: flex;
		align-items: center;
	}
	.product_price > p {
		font-size: 1.25rem;
	}

	.btn_addToCart {
		padding: 0.7rem 0.6rem;
		font-size: 1rem;
		width: 9rem;
	}
}
