.getintouch {
	box-shadow: 0px 4px 20px rgba(208, 208, 208, 0.25);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 80%;
	border-radius: 13px;
	padding: 2rem;
}

.form {
	width: 100%;
	padding: 0.5rem;
}

.split_input {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
}

.form > div,
.split_input > div {
	width: 100%;
	margin-bottom: 0.5rem;
}

.form > div > input,
.form > div > textarea,
.split_input > div > input {
	min-height: 48px;
	border: 1px solid#00000026;
	border: 1.13914px solid rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	font-size: 0.8rem;
	width: 100%;
	padding: 0.8rem;
}

.formSubmit {
	border: none;
	background-color: #5f88f3;
	color: white;
	padding: 0.7rem 3rem;
	border-radius: 2rem;
	font-weight: 600;
}

.submit-button-div {
	text-align: center;
}

.message {
	height: 15vh;
}

p {
	margin: 0.1rem;
}

.desc {
	width: 70%;
	font-size: 0.997rem;
	font-weight: 300;
	color: #8b8b8b;
	text-align: center;
	padding-bottom: 2rem;
}

.heading {
	color: #5f88f3;
	font-size: 1.2rem;
	font-weight: 700;
	font-size: 1.741rem;
	line-height: 2.125rem;
}

.sub_heading {
	font-size: 2.611rem;
	font-weight: 700;
}

.text-danger {
	color: #dc3545 !important;
}

@media (max-width: 50em) {
	.getintouch {
		width: 100%;
		padding: 1rem 1rem 2rem 1rem;
	}
	.sub_heading {
		text-align: center;
		font-size: 1.9rem;
		width: 20rem;
	}
	.desc {
		width: 100%;
	}
	.form {
		width: 100%;
		text-align: center;
		padding: 0rem;
	}
}
