.productOverview {
	padding: 0 3.5rem;
	background: #f3f1f173;
	background-color: aqua;
}

.productOverview > .name {
	font-weight: 600;
	font-size: 1.5rem;
	padding: 1.2rem 0;
}

.productOverview > .desc {
	font-size: 0.75rem;
	text-align: left;
	width: 50vw;
	padding: 0.4rem 0;
}

@media (max-width: 50em) {
	.productOverview {
		padding: 1rem;
	}
	.productOverview > .desc {
		width: 20rem;
		padding: 0.5rem;
		text-align: justify;
	}
}
