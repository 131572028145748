.teams_container{
    background-color: #F5F6FE;
}

.teams_actions{
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 0 2rem;
}

.teams_actions>button{
    background-color: #454FE8;
    padding: 0.5rem 1rem;
    margin: 2rem;
}

.action_btn_icon{
    vertical-align:text-top;
    margin-right: 0.25rem;
}

button{
    padding: 0.5rem;
    margin: 0.25rem;
    border-radius: 5px;
    background-color: #D9D9D93B;
}

.teams_table{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    padding: 1rem 2rem;
}

.teams_table>th{
    border-radius: 10px;
}

.edit-photo-btn{
    background-color: #454FDE;
    color: white;
}

table{
     border-collapse: collapse;
     border-radius: 10%;  
}

th{
    color:white
}

table th:first-child{
    border-radius:10px 0 0 10px;
  }
  
  table th:last-child{
    border-radius:0 10px 10px 0;
  }

table>tr:first-child{
    background-color:#454FDEA1 ;
    border-radius: 10px;
}

.input_conatiner{
    padding: 1rem 4rem 1rem 4rem;
}

.input_conatiner>p{
    text-align: center;
    padding: 1rem;
}

.custom_modal_image_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    
}

.custom_modal_image{
    height: 10rem;
    width: 9rem;
    border-radius: 50%;
    padding: 0.5rem 0;
    object-fit: cover;
}

.teams_form{
    padding: 0 0 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}



.teams_form-btn{
    position: absolute;
    background-color: #454FDE;
    padding: 1rem;
    width: 100%;
    bottom: 2%;
    left: -1%;
}

.form_input{
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: 1px solid #D8D8D8;
    background-color: #F8F8F8;
}

.input_form_container{
    display: flex;
    flex-direction: column;
    position: relative;
    gap:0.25rem
}

.input_form_container>span{
    font-size: 0.75rem;
    color: red;
    padding: 0;
}

.delete-modal-content{
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.button--loading .button__text {
    visibility: hidden;
    opacity: 0;
  }

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}