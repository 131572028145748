


.custom_modal {
	background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
	justify-content: center;
	transform: translate(-50%, -50%);
  }

.custom_modal-body{
	max-height: 100%;
	position: relative;
    overflow-y: auto;
}

.custom_modal.enter-done {
	opacity: 1;
	pointer-events: visible;
}

.custom_modal.exit {
	opacity: 0;
}

.custom_modal-content {
    width:30rem;
  height: 100%;
  background: white;
  color: black;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
	
}

.cutsom_modal.enter-done .modal-content {
	transform: translateY(0);
}

.custom_modal.exit .modal-content {
	transform: translateY(-200px);
}

.custom_modal-title {
	font-weight: 600;
	font-size: 24px;
	/* margin: 0; */
}

.custom_modal-header,
.custom_modal-footer {
	font-size: medium;
	/* line-height: 35px; */
	color: rgba(0, 0, 0, 0.71);
}

.custom_modal-header {
	text-align: center;
	flex-direction: row;
	flex-wrap: wrap;
}

.custom_modal-footer {
	
	padding:1rem 5rem 1.5rem 5rem
}

.custom_modal-button-1 {
	width: 100%;
	height: 50px;
	left: 457.83px;
	top: 710.81px;
    background: #454FDE;
	color: white;
	border-radius: 12.6295px;
	font-weight: 500;
	font-size: medium;
	line-height: 116.5%;
	letter-spacing: 0.02em;
}

.custom_modal-button-2 {
	width: 100%;
	height: 50px;
	left: 1093.01px;
	top: 710.81px;
	background: #f6f6f6;
	border-radius: 12.6295px;
	font-weight: 500;
	font-size: medium;
	line-height: 116.5%;
    color: rgb(130, 130, 130);
	/* or 24px */
	letter-spacing: 0.02em;
	
}

.active_one{
    display: flex;
    align-items: center;
    justify-content: center;
}
