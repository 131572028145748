.tech-item-list{
    display: flex;
    gap:4rem;
    width: 100%;
    padding:1rem 6rem
}

.tech-list{
    display: flex;
   justify-content: space-between;
   align-items: center;
    padding: 2rem 8rem;

}

.owl-stage-outer{
    height: 14rem;
}



.tech_logo{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 20px rgb(208 208 208 / 25%);
}



.logo_image > img {
	height: 20vh;
	width: 20vh;
	max-width: 100%;
	max-height: 100%;
	margin-bottom: 1rem;
	object-fit: cover;
}


.tech_logo:nth-child(4){
    transform: scale(1.5);
	transition: all ease-in-out 0.3s;
}

@media (max-width: 50em) {
    .tech-item-list{
        display: flex;
        gap:2rem;
        flex-direction: column;
        padding:1rem;
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }
    .tech-list{
        display: flex;
       justify-items: center;
       align-items: center;
        padding: 1rem;
        height: 50vw;
        width: 100%;
        
    }
    .tech_logo{
        height: 10rem;
		width: 10rem;
        border-radius: 50%;
        padding: 1rem;
    }
    .tech_logo:nth-child(4){
        transform: scale(1);
    transition: all ease-in-out 0.3s;
    }
}



.logo_image > img:hover {
	cursor: pointer;
	transform: scale(1.25);
	transition: all ease-in-out 0.3s;
	opacity: 92%;
}