.teams {
	display: flex;
	justify-content: space-between;
	padding: 2rem 7rem;
	align-items: center;
	position: relative;
}

.teams2 {
	display: flex;
	justify-content: center;
	padding: 2rem 1rem 6rem 1rem;
}

.teamList {
	width: 90%;
	justify-content: center;
	display: flex;
	gap: 4rem;
}

.teamGrid {
	display: grid;
	grid-template-columns: auto auto auto auto;
	gap: 4rem;
}

@media (max-width: 768px) {
	.teamGrid {
		grid-template-columns: repeat(2, auto);
		gap: 2rem;
	}
}

@media (max-width: 640px) {
	.teamGrid {
		grid-template-columns: repeat(2, auto);
		gap: 2rem;
	}
	.member_image > img {
		height: 20vh;
		width: 20vh;
		max-width: 100%;
		max-height: 100%;

		margin-bottom: 1rem;
		border-radius: 50%;
		object-fit: fill;
	}
}

.member {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.member_image > img {
	height: 20vh;
	width: 20vh;
	margin-bottom: 1rem;
	border-radius: 50%;
	object-fit: cover;
}

.member_image > img:hover {
	cursor: pointer;
	transform: scale(1.25);
	transition: all ease-in-out 0.3s;
	opacity: 92%;
}

.member_name,
.member_designation {
	margin: 0;
}

.member_name {
	font-size: 0.8rem;
	font-weight: 600;
}

.member_designation {
	color: var(--primary-color);
	font-size: 0.8rem;
	font-weight: 400;
}

.socials {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
}

.socials > :hover {
	transition: all ease-in-out 0.2s;
	transform: scale(1.3);
	cursor: pointer;
}

.btn_next,
.btn_previous {
	display: flex;
	padding: 0.8rem;
	border: none;
	background-color: var(--primary-color);
	color: white;
	border-radius: 50%;
}

@media (max-width: 50em) {
	.teams {
		display: grid;
		justify-content: center;
		padding: 1rem;
		position: relative;
	}

	.btn_next,
	.btn_previous {
		display: none;
	}

	.teamList {
		padding-top: 2rem;
		display: grid;
		grid-gap: 1rem;
	}

	.member_image > img {
		height: 10rem;
		width: 11rem;
		border-radius: 50%;
	}
}
