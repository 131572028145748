.landing_container {
	display: flex;
}

.section-1 {
	padding: 6rem 0rem 4rem 9rem;
	width: 100%;
}

.headline {
	font-size: 3.25rem;
	font-weight: 600;
	margin: 0;
	line-height: 4rem;
}

.text_highlight {
	color: var(--primary-color);
}

.summary {
	padding: 0;
	font-size: 1rem;
	width: 28vw;
	padding-bottom: 4rem;
	text-align: justify;
}

.cta_btn {
	padding: 1.2rem 3rem;
	border-radius: 10px;
	border: none;
	background-color: var(--primary-color);
	color: white;
	transition: all ease-in-out 0.2s;
	font-size: 1.1rem;
}

.cta_btn:hover {
	cursor: pointer;
	animation: none;
	transform: scale(1.1);
}

/* Images */

.bgstrip1,
.bgstrip2 {
	position: absolute;
	width: 10vw;
}

.bgstrip1 {
	left: 0;
	bottom: 0;
}

.image_section {
	width: 80%;
	position: relative;
}

.rectangle {
	right: 0;
	position: absolute;
	width: 28vw;
	z-index: -2;
	height: 130%;
}

.screenshots {
	position: absolute;
	height: 130%;
	width: 31vw;
	right: 0;
}

@media (min-width: 3840px) {
	.screenshots {
		top: 0;
		width: 25vw;
		right: 7%;
	}
	.rectangle {
		top: 0;
		width: 18vw;
		right: 2%;
	}
}

/* Images End */

@media (max-width: 50em) {
	.image_section {
		display: none;
	}
	.section-1 {
		padding: 2.5rem;
		width: fit-content;
	}
	.summary {
		padding-top: 1rem;
		width: fit-content;
		padding-bottom: 1rem;
	}
}
