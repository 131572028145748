.productHighlight {
	padding: 3rem 3.5rem;
	background: #f3f1f173;
}

.productHighlight > .name {
	font-weight: 600;
	font-size: 1.5rem;
	padding: 1.2rem 0;
}

.highlights {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 13vh;
}

.highlights > li {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.456rem;
	list-style: none;
	padding: 0.2rem;
	display: flex;
	align-items: center;
	gap: 1rem;
}

@media (max-width: 50em) {
	.productHighlight {
		padding: 1rem;
	}

	.highlights {
		flex-direction: row;
	}

	.highlights > li {
		font-size: 0.9rem;
	}
}
