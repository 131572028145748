.error404-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 3% 0 0 0;
    position: relative;
}

.error404-head{
    font-weight: bolder;
    font-size: 8rem;
    background: -webkit-linear-gradient(#E7027F, #E84434);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error404-content{
   display: flex;
   justify-content: left;
   flex-direction: column;
  
}

.error404-title{
    font-size: 3rem;
    font-weight: 500;
}

.error404-description{
    font-size: 1.25rem;
    font-weight: 300;
    word-spacing: 0.25rem;
}

.error404-image{
    width: 45%;
    display: flex;
    justify-content: center;
}

.error404-image>img{
   height: 35vw;
   width: 35vw;
}

.error404-button{
    padding: 2rem 0rem;
}

.error404-button>button{
    background-color: #E82756;
    color: white;
    padding: 1rem 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
}

.rect-images{
   
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    width: 100%;
    padding-top: 1%;

    position: relative;
   
}

.rect-images>div>img{
    position: absolute;
    bottom: 0;
}