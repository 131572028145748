.analytics_container {
	padding: 0rem 1rem 1rem 1rem;
}

.analytics_cards {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 1rem;
}

.area_graph_card {
	display: flex;
	align-items: stretch;
	flex-direction: column;
	border-radius: 5px;
	background-color: white;
}

.area_graph_button_container {
	display: flex;
	padding: 1rem;
	gap: 1rem;
}

.area_graph_button_container > button:first-child {
	background-color: #454fde;
	padding: 0.5rem 1rem;
	border-radius: 5px;
}

/* .area_graph_button_container > button:last-child {
	color: black;
	padding: 0.5rem 1rem;
	border-radius: 5px;
} */

.bar_donut_container {
	display: flex;
	gap: 1rem;
	padding: 1rem 0rem;
}

.bar_graph_container {
	border-radius: 5px;
	padding: 1rem;
	background-color: white;
}

.donut_graph_container {
	border-radius: 5px;
	padding: 1rem;
	background-color: white;
}

.custom-tooltip {
	background-color: #454fde;
	color: white;
	padding: 0.25rem 0.6rem;
	border-radius: 2rem;
}
