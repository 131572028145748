.Login {
	width: 100vw;
	height: 100vh;
	display: flex;
	overflow: hidden;
}

.login_image > img {
	width: 350px;
	padding-bottom: 10px;
}

.login_fields {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.login_form .login_heading,
.welcome,
.not_registered {
	justify-content: center;
	display: flex;
}

.login_form > .welcome {
	font-size: 1rem;
	font-weight: 300;
	color: gray;
}

.login_heading {
	font-weight: 900;
	font-size: 1.5rem;
}

.fields > button {
	padding: 0.8rem;
	border-radius: 0.3rem;
	color: white;
	background: linear-gradient(0deg, #0056d7, #0056d7),
		linear-gradient(0deg, #0067ff, #0067ff);
}

.fields {
	display: flex;
	flex-direction: column;
	padding: 1.5rem 0;
	gap: 0.5rem;
}

.forgot_pass {
	text-align: right;
	text-decoration: underline;
	color: #0066ff;
	font-weight: 300;
	font-size: 0.75rem;
	text-underline-offset: 3px;
	padding-bottom: 1rem;
	margin-top: -1rem;
	cursor: pointer;
}

.login_using {
	padding-top: 0.7rem;
	display: flex;
	justify-content: center;
	position: relative;
	gap: 1rem;
}

.not_registered {
	padding-top: 1rem;
	font-size: 0.75rem;
	font-weight: 300;
}

.not_registered > span {
	padding-left: 2px;
	text-decoration: underline;
	color: #0066ff;
	font-weight: 900;
	text-underline-offset: 2px;
}

.not_registered > span:hover {
	cursor: pointer;
}

.home_btn {
	position: absolute;
	top: 2%;
	right: 2%;
	font-weight: 900;
	font-size: 0.7rem;
	z-index: 10;
}

.home_btn::before {
	vertical-align: middle;
	content: "<  ";
}

.home_btn:hover {
	cursor: pointer;
	color: gray;
}

.login_using > p {
	position: absolute;
}

.login_using > img {
	padding-top: 2rem;
	width: 3vw;
}

.login_using > img:hover {
	transform: scale(1.1);
	transition: all ease-in-out 0.25s;
	cursor: pointer;
}

.fields > button,
.signUp_form > button {
	font-size: 1rem;
	font-weight: 900;
}

.login_button:hover {
	color: rgb(197, 197, 197);
}

@media (max-width: 50em) {
	.Login {
		width: 100%;
		display: flex;
		overflow: hidden;
	}

	.login_using {
		padding-top: 0rem;
	}

	.login_using > img {
		padding-top: 2rem;
		width: 3rem;
	}

	.login_image > img {
		display: none;
	}
}
