.footer {
	position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;     
}

.container{
	background: #ebeeef;
	padding: 0.5rem 2rem;
	position: relative;
}

.subscribeInput {
	position: relative;
	font-size: 1rem;
}

.subscribeInput > input {
	width: 100%;
	border: none;
	padding: 1rem 0.5rem;
	border-radius: 0.3rem;
}

.emailIcon {
	position: absolute;
	width: 2.5em;
	right: 2%;
	top: 12%;
}

.even-columns {
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap:8rem;
	justify-items: end;
	padding-top:6rem
}

.deskguy{
	position: absolute;
	top:-30%;
	height: 5rem;
	width: 15rem;
	right:8%
}

.footer-nav > ul {
	list-style: none;
	padding-top: 18%;
	text-align: center;
}

.footer-nav > ul > li {
	padding: 0rem 0rem 1rem 0rem;
}

.footer_flex{
	display: flex;
	justify-content: space-between;
}

.social-link-grid{
	padding-top: 30%;
	display: grid;
	grid-template-columns: repeat(2,auto);
	grid-gap:1rem
}

@media (max-width: 50em) {
	.deskguy{
		display: none;
	}
	.footer_flex{
		flex-direction: column;
		gap: 0.5rem;
	}
	.even-columns {
		padding-top: 0.5rem;
		grid-gap:1rem;
		justify-items: start;
		padding-top: 1rem;
		grid-template-columns: repeat(2,auto);
	}
}
