.primary-header {
	padding: 0.5rem 1rem 0.5rem 1rem;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 10;
	
}

.navbar--hidden {
	top: -20%;
  }

.header-boxshadow{
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.nav-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.nav-wrapper > img {	
	
}

.primary-navigation{
	display: flex;
    justify-content: center;
	width:100%;
}

.primary-navigation-home{
	display: flex;
    justify-content: center;
	width:120%;
}

.nav-list {
	display: flex;
	gap: clamp(var(--size-500), 5vw, var(--size-600));
	font-weight: var(--fw-less-bold);
	padding-right: 3rem;
	font-size: 0.8rem;
	font-size: var(--fs-nav);
	list-style: none;
	display: flex;
	gap: 2rem;
	font-weight: var(--fw-less-bold);
}

.nav-list a {
	text-decoration: none;
}

.nav-list li:hover {
	color: rgb(96, 95, 95);
	cursor: pointer;
	font-size: 0.82rem;
	transition: all 0.2s ease-in-out;
}

.cartAvatar {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	align-items: center;
	width: 18%;
	gap: 1rem;
	z-index: 10;
}

.avatar > img {
	width: 3.5rem;
	border-radius: 100px;
}

.desk-avatar{
	background-color:#ee3364;
	color: white;
	padding: 15px;
	border-radius: 100px;
}

.cartAvatar > :hover {
	cursor: pointer;
	/* transform: scale(1.1);
	transition: all ease-in-out 0.2s; */
}

.mobile-nav-toggle {
	display: none;
}


.nav-list a {
	text-decoration: none;
	color: var(--clr-primary-400);
}

.nav-list a:hover,
.nav-list a:focus {
	color: var(--clr-accent-400);
}

.nav_close {
	transform: translateX(0);
}

.nav_open {
	transform: translateX(100%);
}

.list-hidden {
	display: none;
}


@media(min-width:3840px){
	.primary-navigation{
		padding-left: 15%;
	}

}

@media (max-width: 50em) {

.nav-wrapper>img{
	padding: 1% 0 1% 3%;
}
	.primary-navigation {
		flex-flow: column nowrap;
		position: fixed;
		justify-content: space-between;
		background-color: black;
		color: white;
		background-color: black;
		padding: 4rem 0.5rem 5rem 1rem;
		inset: 7rem var(--size-400) auto;
		max-width: 25rem;
		margin-left: auto;
		text-align: left;
		top: 0;
		right: 0;
		height: 100%;
		width: 50%;
		transition: transform 0.3s ease-in-out;
		z-index: 1;
		box-shadow: 0 0 0.75em rgb(0, 0, 0, 0.05);
	}

	.primary-header[data-overlay]::before {
		content: "";
		position: fixed;
		inset: 0;
		/* height: 100vh; */
		background-image: linear-gradient(rgb(0 0 0 / 0), rgb(0 0 0 / 0.8));
	}

	.list-hidden {
		display: list-item;
	}

	li:hover{
		border-bottom: 2px solid #EE3364;
	}

	.nav_close {
		transform: translateX(100%);
	}

	.nav_open {
		transform: translateX(0);
	}

	.nav-list {
		display: grid;
		gap: var(--size-600);
		text-align: left;
		font-weight: var(--fw-bold);
	}

	.primary-navigation[data-visible] {
		display: flex;
		flex-flow: row nowrap;
		transform: translateX(0);
	}

	.mobile-nav-toggle {
		display: block;
		position: fixed;
		top: var(--size-100);
		right: var(--size-300);
		cursor: pointer;
		background: transparent;
		border: 0;
		padding: 0;
		z-index: 2;
	}

	.cartAvatar {
		display: none;
	}
}
