.admin_login_container {
    position: relative;
	display: flex;
	justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.admin_login_heading {
    padding: 2% 0;
	font-weight: 900;
    color: gray;
	font-size: 1rem;
    
}

.admin_heading{
   
   padding-bottom: 5%;
  
   text-align: center;
}


.admin_logo{
    position: absolute;
   top: 5%;
    height: 15%;
    width: 20%;
}




.admin_fields{
    position: absolute;
    top:20%;
    width: 25%;
    padding: 2rem;
    border-radius: 2%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    vertical-align: middle;
}


.admin_login_button {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
	font-weight: 900;
	border-radius: 0.3rem;
	color: white;
    opacity: 0.8;
	background-color: linear-gradient(0deg, #0056d7, #0056d7),
		linear-gradient(0deg, #0067ff, #0067ff);
    position: relative;
    background-color: #0056d7;
}



.button--loading .button__text {
    visibility: hidden;
    opacity: 0;
  }

.admin_login_button:hover {
    color: white;
    opacity: 1
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}