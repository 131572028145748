.layout {
    display: grid;
    grid-template-columns: 19rem 1fr;
    grid-template-rows:auto 1fr;
    min-height: 100vh;
  }

  .admin_user{
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 1rem
  }

  .admin_user>p{
    position: absolute;
    left: 40%;
    font-size: 1.25rem;
    top:38%
  }
  
  .header {
    grid-area: 1 / 1 / 2 / 3;
  }
  
  .aside {
    grid-area: 2 / 1 / 4 / 2;
  }
  
  .main {
    grid-area: 2 / 2 / 3 / 3;
    background-color: #F5F6FE;
    height: 100%;
  }
  
  .footer {
    grid-area: 3 / 2 / 4 / 3;
  }
  