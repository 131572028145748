.imageGallery {
	padding: 3rem 3.5rem;
	background: #f3f1f173;
}

.Gallery {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

.listView {
	display: flex;
	align-items: center;
	border: none;
	padding: 0.8rem;
	background-color: black;
	color: white;
	border-radius: 0.4rem;
	height: 2.5vw;
	font-size: 0.8rem;
}

.back:hover,
.next:hover {
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	transform: scale(1.25);
}


.GallerySlide > img {
	width: 20vw;
	height: 20vw;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 25px;
}

.GalleryGrid > img{
	width: 15vw;
	height: 25vw;
	object-fit: fill;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	border-radius: 25px;
}

.GalleryGrid {
	display: grid;
	grid-gap:1rem;
	grid-template-columns: 1fr 1fr 1fr;
}

.GallerySlide {
	display: flex;
	overflow-x: scroll;
	gap:1rem;
	height: fit-content;
	scroll-behavior: smooth;
	width: 80rem;
}

@media (max-width: 50em) {
	.GalleryGrid {
		display: flex;
		overflow-x: scroll;
		scroll-behavior: smooth;
	}

	.GallerySlide > img {
		padding: 0.2rem;
		border-radius: 10px;
		width: 15rem;
		height: 40vw;
	}

	.imageGallery {
		padding: 1rem;
		background: #f3f1f173;
	}

	.listView {
		display: none;
	}
}
