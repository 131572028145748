.card-container {
	position: relative;
	width: 24rem;
	height: 24rem;
	background: #ffffff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-flow: column wrap;
	border-radius: 15px;
}

.card-container > button {
	position: absolute;
}

.card-container div {
	padding: 0.5rem;
}

.card-header {
	height: 6%;
	background: #ffffff;
}

.card-content {
	font-weight: 700;
	background: #ffffff;
	height: 20%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	padding: 0.85rem 1rem 0rem 1rem;
}

.card-actions {
	height: 10%;
	background: #ffffff;
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
	padding: 0 1rem;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.card-tags {
	font-size: 0.8rem;
	background-color: #8ad0711f;
	border-radius: 0.18rem;
	color: #3d8d2f;
}

.card-media {
	height: 64%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}


.card-image {
	height: 100%;
	padding: 0.5rem;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
	background: url(http://goo.gl/vyAs27) no-repeat scroll 0 0;
	transition: all ease-in-out 0.4s;
	-webkit-filter: brightness(100%);
	-moz-filter: brightness(100%);
	-o-filter: brightness(100%);
	-ms-filter: brightness(100%);
	filter: brightness(100%);
}




.card-title {
	font-size: 1rem;
	background: #ffffff;
}

.card-content:hover img {
	cursor: pointer;
}

.card-content > button {
	background-color: black;
	color: white;
	padding: 0.6rem;
	font-size: 1rem;
}

.card-container:hover .hover_buttons {
	opacity: 1;
}

.card-container:hover .card-image {
	-webkit-filter: brightness(70%);
	-moz-filter: brightness(70%);
	-o-filter: brightness(70%);
	-ms-filter: brightness(70%);
	filter: brightness(70%);
}

.hover_eye:hover,
.hover_cart:hover {
	background: #3d8d2f;
	cursor: pointer;
}


.hover_eye:hover .eye_image {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(8deg)
		brightness(106%) contrast(102%);
}

.hover_cart:hover .cart_image {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(8deg)
		brightness(106%) contrast(102%);
}

.hover_buttons {
	opacity: 0;
	position: absolute;
	z-index: 2;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	gap: 1rem;
}

.hover_buttons > button > img {
	width: 1rem;
}

.hover_buttons > button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.9rem;
	border: none;
	background-color: white;
	border-radius: 0.3rem;
}



.product-heart {
	position: absolute;
	top: 0%;
	right: 0%;
	width: 4rem;
}

@media(max-width:50em){
	.card-container {
		
		width: 22rem;

	}
}

.fiLoading{
	position: absolute;
	margin-right: 7px;
    top: 25%;
	left:45%;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}