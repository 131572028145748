* {
	box-sizing: border-box;
}

input[type="text"],
input[type="url"],
input[type="number"],
select,
textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	resize: vertical;
	font-weight: 500;
	font-size: 16px;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
}

label {
	padding: 5px 5px 5px 0;
	display: inline-block;
	font-size: 16px;
	line-height: 19px;
	/* color: rgba(0, 0, 0, 0.35); */
}

input[type="submit"] {
	background-color: #04aa6d;
	color: white;
	padding: 12px 20px;
	border: none;
	cursor: pointer;
	float: right;
	height: 44px;
	background: #ffffff;
	border-radius: 5px;
}

input[type="submit"]:hover {
	background-color: #45a049;
}

.container {
	padding: 20px;
}

.col-25 {
	float: left;
	width: 50%;
	margin-top: 6px;
	padding: 5px;
}

.col-75 {
	float: left;
	width: 35%;
	margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
	content: "";
	display: table;
	clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
	.col-25,
	.col-75,
	input[type="submit"] {
		width: 100%;
		margin-top: 0;
	}
}

::placeholder {
	font-size: 16px;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: rgba(0, 0, 0, 0.35);
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input::before {
	background: #454fde;
	border-radius: 5px;
	color: #fff;
	content: "Choose File";
	display: inline-block;
	padding: 10px 80px;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 700;
	font-size: 10pt;
}
.custom-file-input:hover::before {
	border-color: black;
}

.custom-file-input {
	color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}

.custom-file-input:active {
	outline: 0;
}
.custom-file-input:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.wrapper {
	position: relative;
}

.project-icon {
	padding: 4px;
	position: absolute;
	top: 50%;
	left: -4px;
	transform: translateY(-50%);
}

.submit-button {
	background-color: #454fde;
	padding: 1rem;
	width: 11.25rem;
	height: 2.75rem;
	border-radius: 5px;
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 14px;
}

.text-danger {
	color: #dc3545 !important;
}

.button--loading .button__text {
	visibility: hidden;
	opacity: 0;
}

.button--loading::after {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	border: 4px solid transparent;
	border-top-color: #ffffff;
	border-radius: 50%;
	animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
	from {
		transform: rotate(0turn);
	}

	to {
		transform: rotate(1turn);
	}
}
