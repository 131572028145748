.dashbaord_card_container{
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    gap: 1rem;
    width:18vw;
    height: 10vw;
}


.dashbaord_card_up{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 5px;
    font-size: 1.5rem;
}

.dashbaord_card_down{
    margin-top:1rem;
    display: flex;
    flex-direction: column  ;
    border-radius: 5px;
    gap: 0.5rem;
}

.dashbaord_card_down>div:last-child{
   color:grey;
   font-size:1rem
}