.product-collection-grid {
	display: grid;
	padding: 1rem 4rem;
	grid-template-columns: auto auto auto;
	grid-gap: 20px;
	justify-content: center;
	padding: 1rem 1rem 2rem 1rem;
	padding-bottom: 7%;
}

.collection-extra-padding{
	padding: 1rem 1rem 8rem 1rem;
}

.product-loader {
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #555;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 640px) {
	.product-collection-grid { grid-template-columns: repeat(1, auto);grid-gap: 20px;justify-content: center;padding: 2rem 1rem 2rem 1rem; }
	
	}
@media (min-width: 640px) {
	.product-collection-grid { grid-template-columns: repeat(1, auto);grid-gap: 20px;justify-content: center; }
	
	}
	

@media (min-width: 768px) {
	.product-collection-grid { grid-template-columns: repeat(2, auto);grid-gap: 20px;justify-content: center; }
  }

  @media (min-width: 1024px){
	.product-collection-grid { grid-template-columns: repeat(3, auto);grid-gap: 20px;justify-content: center; }
	
	
	}

  @media (min-width: 1200px){

	.product-collection-grid { grid-template-columns: repeat(3, auto);grid-gap: 20px;justify-content: center;padding: 3rem 1rem 2rem 1rem;
	}
	
	}

	@media (min-width: 3840px){

		.product-collection-grid { grid-template-columns: repeat(3, auto);grid-gap: 20px;justify-content: center;padding: 3rem 1rem 2rem 1rem;
	}
		
		}