/* <li> */
.nav-item {
	width: calc(var(--nav-size) * 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 1rem;
}

/* Icon Button */
.icon-button {
	width: var(--button-size);
	height: var(--button-size);
	border-radius: 50%;
	padding: 5px;
	margin: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-button svg {
	fill: var(--text-color);
	width: 20px;
	height: 20px;
}

/* Dropdown Menu */

.dropdown {
	position: absolute;
	top: 5rem;
	width: 12%;
	right: -1%;
	transform: translateX(-10%);
	background-color: var(--bg);
	border: var(--border);
	border-radius: var(--border-radius);
	padding: 1rem;
	overflow: hidden;
	background-color: whitesmoke;
	border-radius: 5%;
}

.menu {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.menu-item {
	height: 50px;
	display: flex;
	align-items: center;
	border-radius: var(--border-radius);
}

.menu-item .icon-button {
	margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
	filter: none;
}

.menu-item:hover {
	background-color: #525357;
	color: aliceblue;
	border-radius: 5%;
}

.icon-right {
	margin-left: auto;
}
