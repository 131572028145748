.notification {
	padding: 2px;
	position: relative;
	display: inline-block;
	border-radius: 100px;
}

.notification .cart {
	background-color: var(--primary-color);
	padding: 10px;
	border-radius: 100px;
	display: flex;
}

.notification:hover {
	background: var(--primary-color);
}

.notification .badge {
	position: absolute;
	top: -5px;
	right: -10px;
	text-align: center;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	font-size: small;
	background: var(--primary-color);
	border: 2px solid #fff;
	color: white;
}
