.shopping-cart {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;
}

@media (max-width: 50em) {
	.shopping-cart {
		display: grid;
		padding: 0rem;
	}
}
