.input>input{
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: 1px solid #D8D8D8;
    background-color: #F8F8F8;
}

label{
    font-size: 0.65rem;
    padding-bottom: 0.1rem;
}

.error{
    color: #FF0000;
    font-size: 0.7rem;
    white-space: pre;
}

.input{
    display: flex;
    flex-direction: column;
    position: relative;
}