.pagination {
	display: flex;
	background-color: white;
}

/* Override some Bootstrap pagination styles */
div.pagination {
	padding: 0.25rem;
	margin-top: 0;
	margin-bottom: 0;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

div.pagination p.page-item.active a.page-link {
	color: #445565 !important;
	background-color: #e3e7eb !important;
	border-color: #ced4da !important;
	min-width: 3.5rem;
}

div.pagination a.page-link {
	border: 1px solid black;
	padding: 0.1rem 1rem;
	min-width: 3.5rem;
	text-align: center;
	box-shadow: none !important;
	border-color: #ced4da !important;
	color: #6b88a4;
	font-weight: 900;
	font-size: 1.2rem;
	text-decoration: none;
}

div.pagination a.page-link:hover {
	background-color: #f4f4f4;
} /*# sourceMappingURL=pagination.css.map */
