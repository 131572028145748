.pay-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fbfbfb;
	padding: 1.5rem;
}

.pay-subtext-color {
	color: #0066ff;
}

.pay-subtext .pay-subtext-color {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 10px;
	align-items: center;
	justify-content: space-between;

	font-size: medium;
	line-height: 116.5%;

	letter-spacing: 0.02em;
	color: rgba(0, 0, 0, 0.5);
}
.total-price {
	font-weight: 700;
	font-size: 28px;
	line-height: 116.5%;
	letter-spacing: 0.02em;
	color: #000000;
	padding: 1rem 0rem;
}

.cart-title {
	margin: 0px;
	width: 240px;
	height: 35px;
	left: 1395.75px;
	top: 451.74px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 116.5%;
	/* identical to box height, or 35px */
	letter-spacing: 0.02em;
	color: #000000;
}

.pay-button {
	width: 250px;
	height: 50px;
	left: 1336.11px;
	top: 774.29px;

	background: #3d8d2f;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 116.5%;
	/* identical to box height, or 47px */

	letter-spacing: 0.02em;
	margin-bottom: 10px;
	color: #ffffff;
}

.clear-button {
	width: 250px;
	height: 50px;
	left: 1336.11px;
	top: 774.29px;

	/* background: #3d8d2f; */
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 116.5%;
	/* identical to box height, or 47px */

	letter-spacing: 0.02em;
	margin-bottom: 20px;
	color: black;
}

@media (max-width: 50em) {
	.total-price {
		padding: 1rem 0rem;
	}
}
