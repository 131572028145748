.caseStudy {
	display: flex;
	position: relative;
	padding: 3rem 10rem;
	justify-content: space-around;
}

.case_study_container{
	padding: auto;
}

.case-study-img {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.case-study-img > img {
	width: 25vw;
}

.case-study-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	text-align: left;
	width: 50%;
}

.case-study-content > div {
	font-size: 2rem;
}

.case-study-content > p {
	width: 80%;
	font-weight: 300;
	text-align: justify;
}

.diamondHand {
	position: absolute;
	right: 7%;
	bottom: 10%;
	width: 16vw;
}

.diamond {
	position: absolute;
	left: 14%;
	top: -30%;
	width: 8vw;
}

.case-study-btn {
	width: 10rem;
	background-color: black;
	font-size: 1em;
	color: white;
	border-radius: 5px;
	padding: 1rem 2rem;
	border: none;
	float: left;
}

@media(min-width:3840px){
	.case_study_container{
		margin-top: 2%;
	}
	.case-study-img {
		width: 50%;
		padding: 1rem;
	}

	.case-study-content {
		font-size: 1.25rem;
	}

	.diamondHand{
		width: 12vw;
	}

	.diamond{
	left: 22%;
    top: -26%;
    width: 7vw;
	}
}

@media (max-width: 50em) {
	.case_study_container{
		margin-top: 10%;
	}
	.caseStudy {
		display: grid;
		position: relative;
		padding: 1rem;
	}

	.case-study-img {
		width: 100%;
		padding: 1rem;
	}

	.case-study-img > img {
		width: fit-content;
	}

	.case-study-content {
		width: fit-content;
		padding: 1rem;
	}

	.case-study-content > p {
		width: 100%;
		font-weight: 300;
		text-align: justify;
	}

	.diamond,
	.diamondHand {
		display: none;
	}
}
